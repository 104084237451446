<template>
    <div class="flex justify-content-between">
        <div>
            <span class="block text-500 font-medium mb-3">{{name}} <span v-if="orientation!=null">({{orientation}})</span></span>
            <div class="text-900 font-medium text-xl">
                <Skeleton shape="rectangle" v-if="loading" />
                <span v-else>{{getIndicator(name, orientation)}}</span>
            </div>
        </div>
        <div :class="`flex align-items-center justify-content-center bg-${color}-100 border-round`" style="width:2.5rem;height:2.5rem">
            <i v-if="icon.startsWith('pi-')" :class="`pi ${icon} text-${color}-500 text-xl`"></i>
            <FontAwesomeIcon v-else :class="`text-${color}-500 text-xl`" :icon="icon" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        loading: Boolean,
        value: [Number, String],
        round: {
            type: Boolean,
            default: true
         },
         units: String,
         results: Object,
         temperature: Object,
         name: String,
         orientation: String,
         color: String,
         icon: String
    },
    methods: {
        roundDecimals(num){
            if (this.round)
                return Math.round((num + Number.EPSILON) * 100) / 100;
            else
                return num;
		},
        getValue(valueObj){
			var val = valueObj.value;
			if (valueObj.value == null || typeof valueObj === 'undefined')
				return "-";
			else{
				var units = valueObj.units;
				if (typeof val == 'string')
					return val + " " + units;
				else
					return this.roundDecimals(val) + " " + units;
			}
		},
		getIndicator(name, orientation){
            if (this.units != null){
                return this.getValue({value: this.value, units: this.units});
            }
			var actualDegrees = orientation; // == 'V' ? '90' : '0';
			if (this.results == null || this.temperature == null || !(name in this.results) || !(this.temperature.code in this.results[name]) ||
				!(actualDegrees in this.results[name][this.temperature.code]))
				return "-";
			else{
				return this.getValue(this.results[name][this.temperature.code][actualDegrees]);
			}
		},
    }
}
</script>