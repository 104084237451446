<template>
	<div class="grid layout-main">
		<Toast />
		<ConfirmDialog />
		<Dialog header="Info about tests" :modal="true" v-model:visible="showInfoAboutTests" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw', height: '70%'}">
			<TabView>
				<TabPanel header="Info about tests">
					<div v-for="(value, key) in info" :key="key">
						<p><b>{{key}}</b></p>
							<div class="grid" v-for="(propValue, propKey) in value" :key="propKey">
								<div class="col-8">{{propValue.name}}</div>
								<div class="col-4">
									<span v-if="propValue.value != null && propValue.value.length>0">{{propValue.value}}</span>
									<span v-else>-</span>
								</div>
							</div>
						<hr />
						<!--
						<p><b>{{key}}</b><br />
							<span v-for="(propValue, propKey) in value" :key="propKey">{{propValue.name}} {{propValue.value}}<br /></span>
						</p>
						<hr />
						-->
					</div>
				</TabPanel>
				<TabPanel header="Printing parameters">
					<div v-for="(value, key) in printingPars" :key="key">
						<p><b>{{key}}</b></p>
							<div class="grid" v-for="(propValue, propKey) in value" :key="propKey">
								<div class="col-8">{{propValue.name}}</div>
								<div class="col-4">
									<span v-if="propValue.value != null && propValue.value.length>0">{{propValue.value}}</span>
									<span v-else>-</span>
								</div>
							</div>
						<hr />
					</div>
				</TabPanel>
			</TabView>
			<template #footer>
				<Button label="Close" icon="pi pi-check" autofocus @click="onClose" />
			</template>
		</Dialog>

		<div class="col-3 flex flex-column">
			<div class="card flex-grow-1 mb-0">
				<FileUpload mode="basic" :auto="true" name="dataFile" :url="baseUrl + '/material/upload'" @error="onError" @before-upload="onUploadStart" @progress="onProgress" @upload="onBasicUpload"  class="p-button-warning" style="width:100%" />
				<ProgressBar :value="uploadProgress" class="mt-3">
					{{uploadProgress}}%
				</ProgressBar>
				<div class="mt-4"><h3>Use case</h3></div>
				<div class="flex ml-4 mt-3">
					<div class="flex-grow-1 mr-3" style="max-width: calc(100% - 50px)">
						<Dropdown v-model="useCase" :options="useCases" @change="onUseCaseChanged" placeholder="Select a use case" style="width: 100%; max-width:100%" />
					</div>
					<div>
						<Button class="p-button-warning" :disabled="useCase==null" icon="pi pi-trash" @click="onDeleteUseCase()" />
					</div>
				</div>
				<div class="mt-4"><h3>Use case comparison</h3></div>
				<div class="flex ml-4 mt-3">
					<div class="flex-grow-1 mr-3" style="max-width: calc(100% - 50px)">
						<MultiSelect v-model="useCase2" :options="useCases" placeholder="Select a use case" style="width:100%; max-width:100%" />
					</div>
					<div>
						<Button class="p-button-warning" :disabled="useCase2==null" icon="pi pi-arrow-right-arrow-left" @click="onCompare()" />
					</div>
				</div>
				<div class="mt-4"><h3>Temperature</h3></div>
                <div class="flex ml-4 mt-3">
					<div class="flex-grow-1">
						<Dropdown v-model="temperature" :options="temperatures" @change="onTemperatureChange" optionLabel="name" placeholder="Choose temperature" style="width:100%" />
					</div>
				</div>
                <div class="mt-4 mb-2"><h3>Mechanical properties</h3></div>
				<div class="flex mb-2">
					<div class="flex-grow-1 ml-4 mr-3">
						<Dropdown v-model="mechanicalProp" :options="mechanicalProps" optionLabel="name" placeholder="Select a property" style="width:100%" />
					</div>
                    <div>
                        <Button class="p-button-warning" label="View" :disabled="useCase==null" @click="onMechanicalPropView" />
                    </div>
				</div>
				<div class="mt-4 mb-2"><h3>Thermal properties</h3></div>
				<div class="flex flex-column mb-2">
					<div class="ml-4">
						<FileUpload mode="basic" :auto="true" name="thermalFile" :url="baseUrl + '/material/thermal/upload?use_case=' + useCase" @error="onError" @before-upload="onThermalUploadStart" @progress="onThermalProgress" @upload="onThermalUpload"  class="p-button-warning" style="width:100%" />
						<ProgressBar :value="thermalUploadProgress" class="mt-3">
							{{thermalUploadProgress}}%
						</ProgressBar>
					</div>
					<div class="flex ml-4 mt-3">
						<div class="flex-grow-1 mr-3">
							<Dropdown v-model="thermalProp" :options="thermalProps" optionLabel="name" placeholder="Select a property" style="width:100%" />
						</div>
						<div>
							<Button class="p-button-warning" :disabled="useCase==null" label="View" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!showingChart && !showingComparisonTable" class="col-9" style="display:grid; grid-template-columns: repeat(4,1fr);">
			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-v" color="blue" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Tensile Strength" orientation="2" />
			</div>
			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-v" color="blue" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Tensile Modulus" orientation="2" />
			</div>
			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-h" color="blue" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Tensile Strength" orientation="1" />
			</div>
			<div class="card flex-grow-1 pb-3 pt-3">
				<Indicator icon="pi-arrows-h" color="blue" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Tensile Modulus" orientation="1" />
			</div>
			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-v" color="orange" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Flexural Strength" orientation="2" />
			</div>
            <div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-v" color="orange" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Flexural Modulus" orientation="2" />
			</div>
			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-h" color="orange" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Flexural Strength" orientation="1" />
			</div>
            <div class="card flex-grow-1 mr-0 pb-3 pt-3">
				<Indicator icon="pi-arrows-h" color="orange" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Flexural Modulus" orientation="1" />
			</div>

			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-v" color="cyan" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Ultimate Strength" orientation="2" />
			</div>
			<div class="card flex-grow-1 pb-3 pt-3 mr-3">
				<Indicator icon="pi-arrows-v" color="cyan" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Shear Cord Modulus" orientation="2" />
			</div>
			<div class="card flex-grow-1 mr-3 pb-3 pt-3">
				<Indicator icon="pi-arrows-h" color="cyan" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Ultimate Strength" orientation="1" />
			</div>
			<div class="card flex-grow-1 pb-3 pt-3">
				<Indicator icon="pi-arrows-h" color="cyan" :results="results" :temperature="temperature" :loading="loadingIndicators" name="Shear Cord Modulus" orientation="1" />
			</div>

			<div class="card flex-grow-1 mr-3 pb-3 pt-3" style="grid-column-start: 3; grid-column-end: 4; position: relative;">
				<Indicator icon="fa-solid fa-temperature-half" color="green" value="-" units="W/mK" :loading="loadingThermal" name="Thermal conductivity" />
			</div>
			<div class="card flex-grow-1 mr-0 mb-3 pb-3 pt-3" style="position: relative;">
				<Indicator icon="fa-solid fa-temperature-half" color="green" :value="cte" :round="false" units="mm/mmºC" :loading="loadingThermal" name="CTE" />
			</div>
			<div class="card flex-grow-1 mr-3 pb-3 pt-3" style="grid-column-start: 3; grid-column-end: 4; position: relative;">
				<Indicator icon="fa-solid fa-temperature-half" color="green" value="-" units="J/kgK" :loading="loadingThermal" name="Specific heat capacity" />
			</div>
            <div class="card flex-grow-1 mr-0 pb-3 pt-3">
				<Indicator icon="fa-solid fa-compress" color="purple" value="-" units="J/kgK" :loading="loadingThermal" name="Compression modulus" />
			</div>
            <div class="card flex-grow-1 mr-3 pb-3 pt-3 mb-0" style="grid-column-start: 3; grid-column-end: 4; position: relative;">
				<Indicator icon="fa-solid fa-compress" color="purple" value="-" units="%" :loading="loadingThermal" name="Elongation" />
			</div>

            <div class="card pb-0 pt-0 mr-3 mb-0 flex flex-column align-items-stretch" style="grid-column-start: 1; grid-column-end: 3; grid-row-start: 4; grid-row-end: 7;">
                <div class="flex flex-grow-1 justify-content-center mt-3 mb-3 box-image">
                </div>
				<div class="flex align-items-center justify-content-center mb-3 mt-0">
                    <Button class="p-button-warning" label="Info about tests" :disabled="useCase==null" :loading="loadingInfo" @click="onInfoAboutTests()" />
                </div>
            </div>
		</div>
		
		<div v-if="showingChart" class="col-9 flex flex-column">
			<div class="display flex">
				<div class="card flex-grow-1 mr-3 pb-3 pt-3">
					<div class="flex justify-content-between">
						<div>
							<span class="block text-500 font-medium mb-3">File</span>
							<div class="text-900 font-medium text-xl">{{fileName}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-file text-blue-500 text-xl"></i>
						</div>
					</div>
				</div>
				<div class="card flex-grow-1 mr-3 pb-3 pt-3">
					<div class="flex justify-content-between">
						<div>
							<span class="block text-500 font-medium mb-3">Material</span>
							<div class="text-900 font-medium text-xl">{{material}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
							<i class="pi pi-th-large text-purple-500 text-xl"></i>
						</div>
					</div>
				</div>
				<div class="card flex-grow-1 mr-3 pb-3 pt-3">
					<div class="flex justify-content-between">
						<div>
							<span class="block text-500 font-medium mb-3">Temperature</span>
							<div class="text-900 font-medium text-xl">{{chartTemperature}}</div>
						</div>
						<div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
							<FontAwesomeIcon class="text-blue-500 text-xl" icon="fa-solid fa-temperature-half" />
						</div>
					</div>
				</div>
			</div>

            <div class="card pb-0 pt-0 mr-3 mb-3 flex-grow-1 flex flex-column align-items-stretch" style="grid-column-start: 1; grid-column-end: 4; grid-row-start: 2; grid-row-end: 3;">
                <div style="position: relative; height: 100%;">
					<div style="width:100%;height:100%;" id="plotlyDiv" ref="plotlyDiv">
					</div>
					<div v-if="loadingPropView" style="background: rgba(255, 255, 255, 0.4); position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
						<div class="flex justify-content-center flex-wrap card-container" style="height: 100%">
							<div class="flex align-items-center justify-content-center">
								<ProgressSpinner />
							</div>
						</div>
					</div>
				</div>
            </div>

			<div class="card mr-3 mb-0 flex flex-column align-items-left" style="grid-column-start: 1; grid-column-end: 4; grid-row-start: 2; grid-row-end: 3;">
                <div>
					<Button class="p-button-warning" label="Back" @click="showingChart=false" />
				</div>
            </div>
		</div>

		<div v-if="showingComparisonTable" class="col-9 flex flex-column">
            <div class="card mr-3 mb-3 flex-grow-1 flex flex-column align-items-stretch" style="grid-column-start: 1; grid-column-end: 4; grid-row-start: 2; grid-row-end: 3;">
                <div style="position: relative; height: 100%;">
					<div style="width:100%;height:100%;">
						<DataTable :value="comparison" responsiveLayout="scroll">
							<Column field="var" header="Variable"></Column>
							<Column field="useCase[index]" :header="useCase" v-for="(useCase, index) in comparedUseCases" :key="useCase">
								<template #body="row">
									{{getValue(row.data.useCases[index])}}
								</template>
							</Column>
						</DataTable>
					</div>
					<div v-if="loadingTable" style="background: rgba(255, 255, 255, 0.4); position: absolute; top: 0; left: 0; bottom: 0; right: 0;">
						<div class="flex justify-content-center flex-wrap card-container" style="height: 100%">
							<div class="flex align-items-center justify-content-center">
								<ProgressSpinner />
							</div>
						</div>
					</div>
				</div>
            </div>

			<div class="card mr-3 mb-0 flex flex-column align-items-left" style="grid-column-start: 1; grid-column-end: 4; grid-row-start: 2; grid-row-end: 3;">
                <div>
					<Button class="p-button-warning" label="Back" @click="showingComparisonTable=false" />
				</div>
            </div>
		</div>
	</div>
</template>

<script>
import Plotly from 'plotly.js-basic-dist';
import axios from 'axios';
import Indicator from './Indicator';

export default {
	components: {
		'Indicator': Indicator
	},
	data() {
		return {
			useCase: null,
			useCase2: [],
			useCases: [],
			temperature: null,
			temperatures: [
			],
			mechanicalProps: [
				{name: 'Tensile test', code: 'tensile'},
				{name: 'Bending test', code: 'flexural'},
				{name: 'Shear test', code: 'shear'},
				{name: 'Compression test', code: 'compression_strength'}
			],
			thermalProps: [
			],
			info: [
			],
			printingPars: [
			],
			mechanicalProp: null,
			thermalProp: null,
			ctes: null,
			cte: null,
			fileName: '',
			material: '',
			uploadProgress: 0,
			loadingIndicators: false,
			loadingPropView: false,
			loadingThermal: false,
			loadingTable: false,
			loadingInfo: false,
			results: null,
			thermalUploadProgress: 0,
			showingChart: false,
			showingComparisonTable: false,
			chartTemperature: '',
			comparison: [],
			comparedUseCases: ['', ''],
			showInfoAboutTests: false,
			baseUrl: process.env.VUE_APP_REST_BASE_URL
		}
	},
	mounted(){
		this.temperature = this.temperatures[0];
		this.mechanicalProp = this.mechanicalProps[0];
		this.updateUseCases();
	},
	methods: {
		getData(){
			this.loadingIndicators = true;
			axios
				.get(`/material/data?use_case=${this.useCase}`)
				.then(response => {
					this.parseResponse(response.data);
					this.loadingIndicators = false;
				});
			this.loadingThermal = true;
			axios
				.get(`/material/thermal/data?use_case=${this.useCase}`)
				.then(response => {
					this.ctes = response.data.cte;
					this.updateCte();
					this.loadingThermal = false;
				});
		},
		parseResponse(parsedResponse){
			if ('error' in parsedResponse) return;
			
			this.results = parsedResponse.data;

			if (!this.useCases.includes(parsedResponse.use_case))
				this.useCases.push(parsedResponse.use_case);
			this.useCase = parsedResponse.use_case;

			this.temperatures = [];
			parsedResponse.temperatures.forEach(temp => {
				var name = temp;
				if (temp == 'RT') name = 'Environment temperature';
				this.temperatures.push({name: name, code: temp});
			});
			if (this.temperatures.length > 0)
				this.temperature = this.temperatures[0];
			this.updateCte();
			this.updateInfo();
		},
		roundDecimals(num){
			return Math.round((num + Number.EPSILON) * 100) / 100;
		},
		getValue(valueObj){
			var val = valueObj.value;
			if (typeof valueObj === 'undefined')
				return "-";
			else{
				var units = valueObj.units;
				if (typeof val == 'string')
					return val + " " + units;
				else
					return this.roundDecimals(val) + " " + units;
			}
		},
		updateUseCases(){
			this.loadingIndicators = true;
			axios
				.get('/material/use-cases')
				.then(response => {
					this.useCases = response.data.use_cases;

					if (this.useCase2 == null && this.useCases.length > 0)
						this.useCase2 = this.useCases[0];

					if (this.useCase == null && this.useCases.length > 0){
						this.useCase = this.useCases[0];
						this.getData();
					}else{
						this.loadingIndicators = false;
					}
				});
		},
		updateCte(){
			this.cte = 'N/A';
			if (this.ctes != null && this.temperature != null){
				Object.keys(this.ctes).every(range => {
					var T = parseInt(this.temperature.code);
					if (isNaN(T)) T = 25;
					var fn = Function('var T=arguments[0]; return ' + range);
					if (fn.call(this, T)){
						this.cte = this.ctes[range];
						return false; //break
					}
					return true; //continue
				});
			}
		},
		updateInfo(){
			this.loadingInfo = true;
			axios
				.get(`/material/test-info?use_case=${this.useCase}`)
				.then(response => {
					this.info = response.data.info;
					this.printingPars = response.data.printing_pars;
					this.loadingInfo = false;
				});
		},
		onUseCaseChanged(){
			this.getData();
		},
		onInfoAboutTests(){
			this.showInfoAboutTests = true;
		},
		onClose(){
			this.showInfoAboutTests = false;
		},
		onTemperatureChange(){
			this.updateCte();
			if (this.showingChart) this.onMechanicalPropView();
			if (this.showingComparisonTable) this.onCompare();
		},
		onCompare(){
			this.loadingTable = true;
			this.showingComparisonTable = true;
			axios
				.get(`/material/comparison?use_cases=${this.useCase2}&temp=${this.temperature.code}`)
				.then(response => {
					this.comparison = response.data.data;
					this.comparedUseCases = response.data.use_cases;
					this.loadingTable = false;
				});
		},
		onUploadStart(){
			this.loadingIndicators = true;
			this.uploadProgress = 0;
		},
		onBasicUpload(event){
			this.fileName = event.files[0].name;
			var parsedResponse = JSON.parse(event.xhr.response);
			this.parseResponse(parsedResponse);
			this.loadingIndicators = false;
		},
		onError(){
			this.loadingIndicators = false;
			this.$toast.add({
				severity: "error",
				summary: "Error",
				detail: "Error uploading data to the server",
				life: 3000,
			});
		},
		onProgress(event){
			this.uploadProgress = event.progress;
		},
		onThermalUploadStart(){
			this.loadingThermal = true;
			this.thermalUploadProgress = 0;
		},
		onThermalUpload(event){
			var parsedResponse = JSON.parse(event.xhr.response);
			this.ctes = parsedResponse.cte;
			this.updateCte();
			this.loadingThermal = false;
		},
		onThermalProgress(event){
			this.thermalUploadProgress = event.progress;
		},
		onMechanicalPropView(){
			this.showingChart = true;
			this.chartTemperature = this.temperature.code;
			this.loadingPropView = true;
			axios
				.post('/material/mechanical-prop-view', {use_case: this.useCase, mechanical_prop: this.mechanicalProp.code, temperature: this.temperature.code})
				.then(response => {
					this.fileName = response.data.file_name;
					this.material = response.data.material;
					var chartData = JSON.parse(response.data.chart);
					Plotly.newPlot(
						this.$refs["plotlyDiv"],
						chartData.data,
						chartData.layout,
						{ responsive: true }
					);
					this.loadingPropView = false;
				})
				.catch(() => {
					this.loadingPropView = false;
					this.$toast.add({
						severity: "error",
						summary: "Error",
						detail: "Error getting data from server",
						life: 3000,
					});
				});
		},
		onDeleteUseCase(){
			this.$confirm.require({
                message: 'Are you sure you want to delete "' + this.useCase + '"?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    axios
						.delete('/material/use-cases?use_case=' + this.useCase)
						.then(response => {
							if (response.data.status == 'error'){
								this.$toast.add({
									severity: "error",
									summary: "Error",
									detail: "Error removing the use case",
									life: 3000,
								});
								return;
							}
							this.useCase = null;
							this.updateUseCases();
						});
                },
                reject: () => {
                    //callback to execute when user rejects the action
                },
                onHide: () => {
                    //Callback to execute when dialog is hidden
                }
            });
		}
	}
}
</script>

<style scoped>
.box-image{
	background-image: url('/images/box.png');
	background-size: contain; 
	background-repeat: no-repeat; 
	background-position: center center;
}
</style>
